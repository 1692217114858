/* eslint-disable no-async-promise-executor */
import _ from 'lodash'
import axios from '@/utils/axios'

export const getAll = async () => {
  const response = await axios.get(`/organisation/unit/task/`)

  return response
}

export const createOne = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.post(`/organisation/unit/task/`, formData)

  return response
}

export const updateOne = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.patch(`/organisation/unit/task/${payload.id}/`, formData)

  return response
}

export const deleteOne = async ({ id }) => {
  const response = await axios.delete(`/organisation/unit/task/${id}/`)

  return response
}
