<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Kategorie</label>
          <input
            type="text"
            v-model="task.categorie"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Aufgabe</label>
          <input
            type="text"
            v-model="task.name"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.task.name.$error }"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Beschreibung</label>
          <textarea
            v-model="task.description"
            rows="7"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createOne, updateOne } from '@/services/task'

const tempTask = {
  categorie: '',
  name: '',
  description: '',
}

export default {
  name: 'new-task',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      task: { ...tempTask },
    }
  },
  validations() {
    return {
      task: {
        name: {
          required,
        }
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.task = { ...this.payload }
    },
    save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.task['roleprofile'] = this.parent_id
        if (this.task.id) {
          updateOne(this.task).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          createOne(this.task).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        }
      }
    },
  },
  watch: {},
}
</script>
